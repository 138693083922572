import React, { Component } from "react";

import {
  Icon,
  //Button,
} from "semantic-ui-react";

class About extends Component {

  
  
  render() {
    
    //*************** */
    return (
      <div > 

    {/* Navbar */}

    {/*  */}

<section id="showcaseAbout" className="masthead ">
<div>

<div
className='btnnavAbout'
style={{fontSize:'1.25rem',fontWeight:'bold',marginRight:'50px',padding:'10px',}}
onClick={this.props.handleCloseAbout}
>
{/*  */}
<button
className='back-btn-white'
>
<Icon name="arrow left" />
</button>
{/*  */}
</div>
       
        </div>

    <div className="showcase-content" style={{marginBottom:'0px'}} >
    <span className = 'disflex-paginator' style={{fontSize:'250%',textAlign:'center',marginBottom:'0px'}}>
        <span >
        <img className = '  ' alt="logoCITSYS" width="64" height="86" src='/logoForHome.png'  />
        </span>{' '}
        <span style={{color:'skyblue',marginLeft:'5px'}}>C</span>
  <span style={{color:'orange'}}>IT</span>
  <span style={{color:'white'}}>SYS</span>
        </span>
        <div>
      <h3  style={{fontSize:'150%',color:'white',textAlign:'center',}} className="py-0 ">
      Transforming Life of the People.
      </h3>
      </div>

    <div style={{marginBottom:'130px'}} >
    <h1 style={{fontSize:'250%',textAlign:'center',color:'orange',lineHeight:'42px'}} className="pxy-10-5 ">
      Run your Life and Business as you Reinvent it with our Products,
      Solutions, and Services
      </h1>
      </div>
      


    </div>
  </section>

<div id="maincontainerP">
{/* Section: What We Do */}
<section id="what" className="bg-light py-1">
<div className="containerP">
  <h1 className="head-main">
  Who We Are
  </h1>
      <div>
        <h3>CITSYS is a Technology Company that Designs,
        Develops, Sells and Supports IT Products and Services.

         </h3>
         </div>

</div>
</section>
<br/><br/>
{/*  */}
<div className="containerP p-1">
<div className="rowAbout">
<div className ="side">
    <h1> Our Mission </h1>
</div>

<div className ="main">
    <div className="font-18B color-black" style={{textAlign: 'justify'}}>
      Our Mission is to make professional and personal life of every
     person much simpler, more pleasant, more productive and empower
     every person and every organization to achieve more through our
     innovative hardware, software, and services.
     </div>
</div>

</div>
{/*  */}
<br/><br/><br/>

<div className="rowAbout">
<div  className ="side nodisplay-tab">
    <h1> Our Vision </h1>
</div>
<div className ="main">
    <div className="font-18B color-black" style={{textAlign: 'justify'}}>
      Our Vision is to provide the best technology,
    great products and unmatched services to the world.
    </div>
</div>

<div  className ="side nodisplay">
    <h1> Our Vision </h1>
</div>
</div>
{/*  */}
<br/><br/><br/>
<div  className="rowAbout">
<div   className ="side">
    <h1> Our Products </h1>
</div>
<div  className ="main">

  {/*  */}
<div className="font-20B color-blue" style={{textAlign: 'justify',marginBottom:'2px'}}>
FINDPROP.IN </div>
<div className="font-18B color-black"style={{textAlign: 'justify'}}>
  
As there are no fake listings or fake data
<a href="https://findprop.in"  > findprop.in </a> is India's only Property Website to provide the most
genuine and accurate information about residential and commercial real estate
and therefore the most trusted Property Website to find a home or commercial
space in India. It provides a platform for property buyers and sellers
to find properties of interest and information on the real estate space
in a transparent and unambiguous manner. 
</div>
<div className="font-18B color-black" style={{textAlign: 'justify'}}>
<a href="https://findprop.in"  >findprop.in </a> has all the required and the most advance features such
as Users can Post their property or requirement. Advance and easy search
options to filter, shortlist, and finally find the exact property user
are looking for. Property or Project have all details like photos,
amenities, nearby amenities such as ATM, Park, bus, school, restaurant
etc, floor plans, specifications and many more

</div>

  {/*  */}
  <br/>
  <div className="font-20B color-blue" style={{textAlign: 'justify',marginBottom:'2px'}}>
  D2DC.IN </div>
<div className="font-18B color-black" style={{textAlign: 'justify'}}>
<a href="https://d2dc.in" target='_blank' >d2dc.in</a> is a software as a service (SaaS).
It is complete Resource Management | Client
Management | Office Management | Networking software for Real
Estate Individual and Enterprise level Brokers and Consultants.
 It will make their business process simple and smooth and
 help them to provide Real Estate Services to their clients
 more Efficiently and Effectively than ever before
</div>
<div className="font-18B color-black" style={{textAlign: 'justify'}}>
It enables the user to take care of every aspect of the business. In
a single place, you can manage all your Leads, Properties, Enquiries,
Contacts, Finances, Agreements, Important Notes, Site-visits, Tasks,
Meetings, Follow-ups, and more.
</div>

{/*  */}

<br/>
  <div className="font-20B color-blue" style={{textAlign: 'justify',marginBottom:'2px'}}>
  PP2P.IN </div>
    <div className="font-18B color-black" style={{textAlign: 'justify'}}>
    <a href="https://pp2p.in" target='_blank' >pp2p.in</a> is a Work Management and Self as well as
    Business-to-Business Finance Management software as a service (SaaS) for
    enterprises.  It will make their business process
    simple and smooth and help them to provide Services
    to their clients more efficiently and effectively
    than ever before.
    </div>

    <div className="font-18B color-black" style={{textAlign: 'justify'}}>
    It will also eliminate any point of difference or
    dispute in sale, purchase, payment, receipt between
    buyer and suppliers.
    </div>

    <div className="font-18B color-black" style={{textAlign: 'justify'}}>
    PP2P  also works as an online B2B advertisement place,
    connecting buyers with dealers and suppliers while
    benefiting all mutually and increasing their revenues.
    </div>
          
    <br/>
  <div className="font-20B color-blue" style={{textAlign: 'justify',marginBottom:'2px'}}>
  EKHATA.IN </div>
  <div className="font-18B color-black" style={{textAlign: 'justify'}}>
    <a href="https://ekhata.in" target='_blank' >ekhata.in</a> is a 
    Buyers-to-Sellers Sale / Purchase / Payment Account Management software as a service (SaaS) for
    Individuals and Shopkeepers. It Digitalize and Ease their Sale / Purchase and Accounting of Daily Use Products 
    and make their buying / selling process simple and smooth.
    </div>

    <div className="font-18B color-black" style={{textAlign: 'justify'}}>
    It will also eliminate any point of difference or
    dispute in sale, purchase, payment, receipt between
    buyer and seller.
    </div>
{/*  */}
</div> 
</div>
{/*  */}
<br/><br/><br/>

<div className="rowAbout">
<div className ="side  nodisplay-tab">
    <h1> Our Team </h1>
</div>
<div className ="main">
    <div className="font-18B color-black" style={{textAlign: 'justify'}}>
    Behind every great human achievement, there is a team.
    We’ve assembled a great team of talented, innovative, highly
    motivated and incredibly passionate people with a deep background
    in information and technology and having decades of experience
    together.
    </div>
    <div className="font-18B color-black" style={{textAlign: 'justify'}}>
    Do it right the first time, do it fast, and have fun while you
    do it. Our wonderful and enthusiastic team work like that and
    committed towards bringing best technology, great products and
    unmatched services to people.
</div>
</div>
<div className ="side  nodisplay">
    <h1> Our Team </h1>
</div>

</div>
{/*  */}
</div>
<br/><br/><br/>
  </div>

{/* Footer */}



{/*  */}
    </div>
    );
  }
}

export default About
