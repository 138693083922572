import React, { Component } from "react";
//import { Link } from "react-router-dom";

import { connect } from "react-redux";
import format from "date-fns/format";
import writtenNumber from "written-number";
import axios from "axios";
import { toastr } from "react-redux-toastr"; 
import Paginator from "../paginator/Paginator";
import { resetForm ,} from "../../actions/commonAction";
import { openModal } from "../../actions/modalAction";
import * as actions from "../../actions/financeAction";
import SaleBookEdit from "./SaleBookEdit";
import FinanceEditToAccept from "./FinanceEditToAccept";
import AddViewImageContra from "../image/AddViewImageContra";
import {renderMonth,capitalizeFirst,renderWhatsappButton,
  //renderPreviousMonth,
} from "../functions/Functions";
import { getMonth,getYear } from 'date-fns'
import { 
  Table,
  Container,
  Divider,
  Segment,
  //Input,
  //Button,
  Label,
  Popup,
  Icon,
  Modal,
 
} from "semantic-ui-react";
import Confirm from "../common/Confirm_1";
import AllFinanceSA from './AllFinanceSA'
//import AdFindprop from "../advertisement/AdFindprop";

class SaleBook extends Component {
  state = {
    pageO:'',
    id: "",
    idOfContraEntry:'',
    path:"",
    imagePathContra:[],
    checkedStatus:'',
    entryFor:'',
    entryType:'',
    open: false,
    allFinanceSAModalOpen: false,
    renderType: "Payment",
     editModalOpen: false,
     addViewImageModalOpen: false,
     viewPdfModalOpen:false,
     financeEditToAcceptModalOpen: false,
   
      monthNum:getMonth(new Date()),
      year:getYear(new Date()),
      monthWord:''
  
  };

  componentDidMount() {
    
    const {limit} = this.props
    const values = {entryType: 'Sale', month:renderMonth(this.state.monthNum),  ...(this.props.form !== undefined && this.props.form.values) };
    
    const valuesTGT = {entryType: 'Sale', month:renderMonth(this.state.monthNum),  ...(this.props.form !== undefined && this.props.form.values)  ,search: 'totalGT'};
    this.props.searchSaleBook(values,limit);

    this.props.searchSaleBook(valuesTGT,limit);
  
  }
  componentWillUnmount(){
    this.props.resetForm('SaleBookSearch')
    this.props.resetLoading()
  }
  handleSearchSaleBook = () => {
    this.props.openModal("SearchSaleBookModal");
  };

  show = (id ,idOfContraEntry) => {

    this.setState({ open: true, id: id, idOfContraEntry:idOfContraEntry });
  };

  

  handleRefresh = ()=>{
    const {offset, limit} = this.props;
    const values = {entryType: 'Sale', month:this.props.accountMonth,  ...(this.props.form !== undefined && this.props.form.values) };
    this.props.searchSaleBook(values, limit,offset)
  }

handleRefreshAll = ()=>{
  const {offset, limit} = this.props;
  const values = {entryType: 'Sale', month:this.props.accountMonth,  ...(this.props.form !== undefined && this.props.form.values) };
  this.props.searchSaleBook(values, limit,offset)

  const valuesTGT = {entryType: 'Sale', month:this.props.accountMonth,  ...(this.props.form !== undefined && this.props.form.values)  ,search: 'totalGT'};
  this.props.searchSaleBook(valuesTGT, limit,offset)

}

handleConfirm = async () => { 
  const did = this.state.id;
  const  valueDel = {month:this.props.accountMonth,idOfContraEntry:this.state.idOfContraEntry}


//************
try {
  const res = await axios.put(`/api/FinanceDelete/${did}`,valueDel);

      if(res.data.msg ==='success'){
        this.handleRefreshAll()
        this.setState({ open: false });
        toastr.success('Deleted Successfully')
        }
        if(res.data.msg ==='errorStatus'){
          this.handleRefreshAll()
          this.setState({ open: false });
          toastr.error("You can't Delete this")
          }

        

  }catch (error) {
 toastr.error("Oops", "Something went wrong.Please Try Again");
}
//**********

};
   //**********
  handleCancel = () => this.setState({ result: "cancelled", open: false });
//**********************************
  handleEditModalOpen = (id) => this.setState({ editModalOpen:true, id:id })
  handleEditModalClose = () =>{
     this.setState({ editModalOpen:false })
     }  //
  handleEditModalCloseSubmit = () =>{
    this.handleRefreshAll()
       this.setState({ editModalOpen:false })
  }
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
handleAllFinanceSAModalOpen = (accountId,month) => this.setState({ allFinanceSAModalOpen:true, accountId,month});
handleAllFinanceSAModalClose = () => {
  this.setState({ allFinanceSAModalOpen:false, })
};
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
handleFinanceEditToAcceptModalOpen = (id) => this.setState({ financeEditToAcceptModalOpen:true, id:id })
handleFinanceEditToAcceptModalClose = () =>{
   this.setState({ financeEditToAcceptModalOpen:false })
 }  //
handleFinanceEditToAcceptModalCloseSubmit = () =>{
  this.handleRefresh()
   this.setState({ financeEditToAcceptModalOpen:false })
}
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  handleAddViewImageModalOpen = (id,idContra,path,checkedStatus,entryFor,entryType) => this.setState({ addViewImageModalOpen:true, id:id,idOfContraEntry:idContra,imagePathContra:path,checkedStatus,entryFor,entryType})
  handleAddViewImageModalClose = () => this.setState({ addViewImageModalOpen:false, })
//************
// handleViewPdfModalOpen = (id,idContra,path,checkedStatus,entryFor,entryType) => this.setState({ viewPdfModalOpen:true, id:id,idOfContraEntry:idContra,imagePathContra:path,checkedStatus,entryFor,entryType})
// handleViewPdfModalClose = () => this.setState({ viewPdfModalOpen:false, })


//**************************************
renderButton = (id,idOfContraEntry,checkedStatus) =>{
  
  if((checkedStatus!=='Checked Ok')){
    return (
      <span
      onClick={() => this.show(id,idOfContraEntry)}
      className="icon-button"
      >
      <img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          
        </span>
  )
}else{
  return (
    <span>
    </span>

)
}

}

  //******************
   renderAddOrViewImageButton = (id,idContra,path,checkedStatus,entryFor,entryType) =>{

  if(path !== undefined && path.length <1){
    return (
    <span
    onClick={()=>this.handleAddViewImageModalOpen(id,idContra,path,checkedStatus,entryFor,entryType)}
    className="icon-button"
    >
    <img className= 'yimg ' src='/imageAddIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
    </span>

  )
  }
  //***
  if(path !== undefined && path.length >0){
    if(path[0].indexOf('image') !== -1){
    return (
    <span
      onClick={()=>this.handleAddViewImageModalOpen(id,idContra,path,checkedStatus,entryFor,entryType)}
    className="icon-button"
    >
    <img className= 'yimg ' src='/imageViewIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
    </span>

  );
  }
  //^^
  if(path[0].indexOf('pdf') !== -1){
  return (
  <span
  onClick={()=>this.handleAddViewImageModalOpen(id,idContra,path,checkedStatus,entryFor,entryType)}
  //onClick={()=>this.handleViewPdfModalOpen(id,idContra,path,checkedStatus,entryFor,entryType)}
 className="icon-button"
  >
  <img className= 'yimg ' src='/pdfViewIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
  </span>

);
}
//^^
  }
//**

}
  //###################################
   renderCheckedStatusButton = (entryType,checkedStatus,) =>{
    if(checkedStatus==='Not Checked'){
      if((entryType==='Sale')||(entryType==='Sale Return')){
        return (
          <span
          // onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
          className="icon-button"
          >
          <img className= {this.nameClass(entryType)} src='/statusIconOnHold.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }
      

    }
    if(checkedStatus==='Checked Ok'){
      if((entryType==='Sale')||(entryType==='Sale Return')){
        return (
          <span
        //  onClick={()=>this.handleFinanceEditToAcceptModalOpen(_id)}
          className="icon-button"
          >
          <img className= {this.nameClass(entryType)} src='/statusIconOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }
      
    }
    // if(checkedStatus==='Checked Not Ok'){
    //   if((entryType==='Sale')||(entryType==='Sale Return')){
    //     return (
    //       <span
    //     //  onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
    //       className="icon-button "
    //       >
    //       <img className= {this.nameClass(entryType)} src='/statusIconNotOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
    //       </span>
    //     )
    //   }

      
    // }
    // if(checkedStatus==='Unlocked'){
    //   if((entryType==='Sale')||(entryType==='Sale Return')){
    //     return (
    //       <span
    //     //  onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
    //       className="icon-button "
    //       >
    //       <img className= {this.nameClass(entryType)} src='/statusIconInProgress.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
    //       </span>
    //     )
    //   }

      
    // }
    //^^^^^^^^^^^ last bracket
  }


  //^^^^^^^^^^^^^^^
   nameClass =(entryType)=>{
    if((entryType==='Sale')||(entryType==='Sale Return')){
      return 'yimg btn-disabled'
    }
    
  }
  // ^^^^^^^^^^^^^^^ 
  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  
//*******************************************************
  renderList=(finance)=> {
    const {
      entryType,
      description,
      //accountName,
      accountId,
      //bankAccountName,
      //transactionType,
     // accountRef,
      amount,
      entryDate,
   
      idOfContraEntry,
      checkedStatus,
      _id
    } = finance;

    const userType = this.props.userA !== null && this.props.userA.userType
    const accountDetail = this.props.usersG !== undefined && this.props.usersG !== null &&  this.props.usersG.filter(val => {
  
        return val._id === accountId.toString();
      })[0];
                // //^^^^^^^^^^^^^^^
    return (
      <Table.Row   key={_id}>
        <Table.Cell>{format(new Date(entryDate), "MMM d yyyy")}</Table.Cell>
        <Table.Cell>
          <>
          <span 
        className="color-indigo font-14B underline cursor-pointer"
        onClick={()=>this.props.openModal('UserDetailModal',{id:accountId})}
       >
 {userType==='Shop Owner'? accountDetail!== undefined && accountDetail.uname : accountDetail!== undefined && accountDetail.shopName}{' '}({accountDetail!== undefined && accountDetail.address}{' '}{userType==='Shop Owner'? '#'.concat(accountDetail!== undefined && accountDetail.userId):''})
        </span>
        </>
        </Table.Cell>
        <Table.Cell>{description && capitalizeFirst(description)}</Table.Cell>

        {/* <Table.Cell>{accountRef}</Table.Cell> */}
        <Table.Cell>{amount}</Table.Cell>
        <Table.Cell>
        <div className='disflex-paginator'>
        <span
        className="icon-button ml-20"
        onClick={()=>this.handleAllFinanceSAModalOpen(accountId,this.props.accountMonth)}
        >
        <img className= 'yimg ' src='/inrIcon.jpg' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
        <span
        onClick={()=>this.handleEditModalOpen(_id)}
        className="icon-button"
        >
        <img className= 'yimg ' src='/editIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
        
        {/* {this.renderAddOrViewImageButton(_id,idOfContraEntry,imagePathContra,checkedStatus,entryFor,entryType)} */}
        {this.renderCheckedStatusButton(entryType,checkedStatus,_id)}
        {this.renderButton(_id,idOfContraEntry,checkedStatus)}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
//*********************************** for mobile
renderListMobile=(finance)=> {
  const {
    entryType,
    description,
    accountName,
    accountId,
    //bankAccountName,
    //transactionType,
    //accountRef,
    amount,
    entryDate,
 
    idOfContraEntry,
    checkedStatus,
    _id
  } = finance;

    
  const colorRibbon = ((entryType === 'Sale')) ? 'blue': 'black';
  
  const userType = this.props.userA !== null && this.props.userA.userType
  const accountDetail = this.props.usersG !== undefined && this.props.usersG !== null && this.props.usersG !==undefined && this.props.usersG.filter(val => {

      return val._id === accountId.toString();
    })[0];
    //******************
const valWhatsapp = {
  uwhatsapp:accountDetail!== undefined && accountDetail.uwhatsapp,
  amount:amount,
  mes1:`On ${format(new Date(entryDate), "MMM d yyyy")} made a Sale of`
}

// *******
  return (
    <Segment key={_id}>
      <Label color={colorRibbon} ribbon>
{entryType}
      </Label>
      
      <span
        className="icon-button ml-20"
        onClick={()=>this.handleAllFinanceSAModalOpen(accountId,this.props.accountMonth)}
        >
        <img className= 'yimg ' src='/inrIcon.jpg' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
      
      <span
      onClick={()=>this.handleEditModalOpen(_id)}
      className="icon-button"
      >
      <img className= 'yimg ' src='/editIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      </span>
      
      {/* {this.renderAddOrViewImageButton(_id,idOfContraEntry,imagePathContra,checkedStatus,entryFor,entryType)} */}
      { this.renderCheckedStatusButton(entryType,checkedStatus,_id)}
      {renderWhatsappButton(valWhatsapp)}
      {this.renderButton(_id,idOfContraEntry,checkedStatus)}
        <br/>
      <br />


      <Divider fitted />

      <div >
      {entryDate && (
        <div className='row'>
        <div className='side'>
          <b>Date</b>
        </div>
        <div className='main'>

        <b>{format(new Date(entryDate), "MMM d yyyy")}
        </b>
        </div>

        </div>
      )}
      </div>

      <div   >
      {entryType && (
        <div className='row'>
        <div className='side'>
          <b>Entry Type</b>
        </div>
        <div className='main color-date'>

        <b>{entryType}
        </b>
        </div>

        </div>
      )}
      </div>

      <div >
      {accountName && (
        <div className='row'>
        <div className='side'>
          <b>Account Name</b>
        </div>
        <div 
        onClick={()=>this.props.openModal('UserDetailModal',{id:accountId})}
        className='main'>
        <b className="color-indigo">
        {userType==='Shop Owner'? accountDetail!== undefined && accountDetail.uname : accountDetail!== undefined && accountDetail.shopName}{' '}({accountDetail!== undefined && accountDetail.address}{' '}{userType==='Shop Owner'? '#'.concat(accountDetail!== undefined && accountDetail.userId):''})
            </b>
        </div>

        </div>
      )}
      </div>

      <div >
      {description && (
        <div className='row'>
        <div className='side'>
          <b>Description</b>
        </div>
        <div className='main'>
          <b>{capitalizeFirst(description)}</b>
        </div>

        </div>
      )}
      </div>

      {/*<div >
      {bankAccountName && (
        <div className='row'>
        <div className='side'>
          <b>Bank Account Name</b>
        </div>
        <div className='main'>
          <b>{bankAccountName}</b>
        </div>

        </div>
      )}
      </div>*/}

      {/* <div >
      {accountRef && (
        <div className='row'>
        <div className='side'>
          <b>Entry Ref</b>
        </div>
        <div className='main'>
          <b>{accountRef}</b>
        </div>

        </div>
      )}
      </div> */}

      {/*<div >
      {transactionType && (
        <div className='row'>
        <div className='side'>
          <b>Transaction Type</b>
        </div>
        <div className='main'>
          <b>{transactionType}</b>
        </div>

        </div>
      )}
      </div>*/}

      <div >
      {amount && (
        <div className='row'>
        <div className='side'>
          <b>Amount INR</b>
        </div>
        <div className='main'>
          <b>{amount}{' '}(   {capitalizeFirst(writtenNumber(amount, {
      lang: "enIndian"
    }))}  )
          {" "}</b>
        </div>

        </div>
      )}
      </div>


    </Segment>
  );
}

//**********************
  back = () => {
    const {
      offset,
      limit

    } = this.props;

    const values = {entryType: 'Sale', month:this.props.accountMonth,  ...(this.props.form !== undefined && this.props.form.values) };
    

    if (offset === 0) {
      return;
    }

    this.props.searchSaleBook(values, limit, (offset - limit));
    window.scroll(0,11000)
  };

  advance = () => {
    const {
      offset,
      limit,
      count

    } = this.props;

    const values = {entryType: 'Sale', month:this.props.accountMonth,  ...(this.props.form !== undefined && this.props.form.values) };
    
    if (offset + limit > count) {
      return;
    }

    this.props.searchSaleBook(values, limit, (offset + limit));
    window.scroll(0,0)
  };
  handleGoToPage = () => {
    const {

      limit,
      count

    } = this.props;

    const values = {entryType: 'Sale', month:this.props.accountMonth,  ...(this.props.form !== undefined && this.props.form.values) };
    
      // use Math.abs for strict equality ===  to work and safe guard against negative value from user
      if (Math.abs(this.state.pageO) === 0) {
        return;
      }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)


    this.props.searchSaleBook(values, limit, (page * limit));
      window.scroll(0,0)

  };

  //****************** */
  renderPaginator() {
    if (this.props.saleBooks.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


    renderLoading() {
      if (this.props.fetching===true) {
        return (
          <Container text>
        <div><span style={{marginLeft: '200px',fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
        </Container>
        );
      }
    }
    renderNoEntry() {
      if (this.props.noEntry===true) {
        return (
          <>
          <Table.Row className="nodisplay-at-mobile disflex-center">
            <Table.Cell>
            <span className="font-20B color-dodgerblue">No Entry Exist</span>
            </Table.Cell>
          </Table.Row>
        <Segment className="nodisplay-at-tab disflex-center"><span className="font-20B color-dodgerblue">No Entry Exist</span></Segment>
        </>
        );
      }
    }
    
  //########## RENDER ###############################
  render() {

//     const colorRibbon = this.props.saleBooks[0]!== undefined && ((this.props.saleBooks[0].entryType === 'Sale')) ? 'blue': 'black'
// const EntryType = ()=>{
//   if(this.props.saleBooks[0]!== undefined && ((this.props.saleBooks[0].entryType === 'Sale'))){
//     return 'Sale'
//   }
//   if(this.props.saleBooks[0]!== undefined && ((this.props.saleBooks[0].entryType === 'Sale Return'))){
//     return 'Sale Return'
//   }

// }

    let sumT = this.props.saleBooks.reduce((acc, val) => acc + val.amount, 0);

    const { open,  } = this.state;

   
      return (
        <div >
        {/**/}
        <div >
        {/**/}

          <Confirm
            open={open}
            onCancel={this.handleCancel}
            onConfirm={() => this.handleConfirm()}
          />
        {/* EditCashBook Modal*/}

        <Modal
      open={this.state.editModalOpen}
      onClose={this.handleEditModalClose}
      basic
      closeOnEscape={false}
      closeOnDimmerClick={false}
      // size='small'
    >

      <Modal.Content>
      <SaleBookEdit
      id={this.state.id}
      month={this.props.accountMonth}
      handleEditModalCloseSubmit={this.handleEditModalCloseSubmit}
      handleEditModalClose= {this.handleEditModalClose}
       />

      </Modal.Content>

    </Modal>
    {/* FinanceEditToAccept Modal*/}

    <Modal
  open={this.state.financeEditToAcceptModalOpen}
  onClose={this.handleFinanceEditToAcceptModalClose}
  basic
  // closeOnEscape={false}
  // closeOnDimmerClick={false}
  // size='small'
>

  <Modal.Content>
  <FinanceEditToAccept
  id={this.state.id}
  month={this.props.accountMonth}
  handleFinanceEditToAcceptModalCloseSubmit={this.handleFinanceEditToAcceptModalCloseSubmit}
  handleFinanceEditToAcceptModalClose= {this.handleFinanceEditToAcceptModalClose}
   />

  </Modal.Content>

</Modal>
    {/* Add Image Modal*/}
    <Modal
    open={this.state.addViewImageModalOpen}
    onClose={this.handleAddViewImageModalClose}
    basic
    closeOnEscape={false}
    closeOnDimmerClick={false}
    // size='small'
    >

    <Modal.Content>
    <AddViewImageContra
    idEnt={this.state.id}
    // idOfContraEntry={this.state.idOfContraEntry}
    // imagePathContra={this.state.imagePathContra}
    // checkedStatus={this.state.checkedStatus}
    // entryFor={this.state.entryFor}
    // entryType={this.state.entryType}
    handleAddViewImageModalClose= {this.handleAddViewImageModalClose}
    handleRefresh = {this.handleRefresh}
    />
    </Modal.Content>

    </Modal>
    
{/* AllFinanceSA Modal*/}
<Modal
        open={this.state.allFinanceSAModalOpen}
        onClose={this.handleAllFinanceSAModalClose}
        basic
        closeOnEscape={false}
        closeOnDimmerClick={false}
        style={{margin:'0px',padding:'0px'}}
        >

        <Modal.Content>
        <div className="mb-10"
style={{color:'white'}}
>
     
      <button

onClick={this.handleAllFinanceSAModalClose}

className='back-btn color-white'
>
<Icon name="arrow left" />

</button>
    </div>
        <AllFinanceSA 
        accountId = {this.state.accountId}
        month = {this.state.month} 
        handleAllFinanceSAModalClose = {this.handleAllFinanceSAModalClose}
       
        />

        </Modal.Content>
        </Modal>

         {/* AllFinanceSA Modal end*/}
    {/**/}
    </div >
    {/**/}
        <div className='nodisplay'>

        <Container  >
                    {/**/}
                    <div className='cashbook-H'>

                    <div
            style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',}}
            onClick={this.handleRefresh}>
                  <span className='icon-button px-10 border1' > <Icon name='refresh' />Refresh</span >
                </div>

            <div  style={{display: 'flex',flex: '75%',justifyContent: 'center',}} >
            <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              Sale Book # {this.props.accountMonth}
            </div>
            </div>

            <div
            style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
            onClick={this.handleSearchSaleBook}>
                  <span className='icon-button px-10 border1' > <Icon name='search' />Search</span >
                </div>


            </div>
            {/**/}
          <Segment  attached>
          {/* <div >
            <Label color={colorRibbon} ribbon="right"  >
            {" "}
              {EntryType()}
            </Label>
            </div> */}
            
            

            {this.renderLoading()}
            <Table compact="very" celled striped >
              <Table.Header>
                <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>A/C Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>

                {/* <Table.HeaderCell>Entry Ref</Table.HeaderCell> */}
                <Table.HeaderCell>Amount <Icon className="rupee" ></Icon></Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>

                {this.props.saleBooks.map(this.renderList)}
                {this.renderNoEntry()}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  {/* <Table.HeaderCell /> */}
                  <Table.HeaderCell><span className='color-date'>

                  <Popup trigger={<span className="border-circle mr-05">?</span>
                  } wide='very'>
                  <b>Total of the all entries For current Search Criteria</b>
                  </Popup>
                  <b>Grand Total</b></span></Table.HeaderCell>
                  <Table.HeaderCell><span style={{color:'blue'}}><b><Icon className="rupee" ></Icon>{this.props.sumGTotalSale}</b></span></Table.HeaderCell>

                  <Table.HeaderCell><span style={{color:'teal'}}>

                  <Popup trigger={<span className="border-circle mr-05">?</span>
                  } wide='very'>
                  <b>Total of the all entries on the current page</b>
                  </Popup>
                  <b>Total</b></span></Table.HeaderCell>
                  <Table.HeaderCell><span style={{color:'blue'}}><b><Icon className="rupee" ></Icon>{sumT}</b></span></Table.HeaderCell>
                  <Table.HeaderCell />

                </Table.Row>
              </Table.Footer>
            </Table>

          </Segment>
          <Segment style={{ marginLeft: '0px' }}>
          <div className='disflex-paginator '>
{this.renderPaginator()}
<div className="disflex-paginator mb-0">
  <div className= 'font-paginator'>Go To Page</div>
  <form>
<input
className='inputPage'
placeholder="number"
name="pageO"
type="number"
value={this.state.pageO}
onChange={this.onChange}
/>
<span  className="btnNumber"  onClick={this.handleGoToPage} >
Submit
</span>
</form>

</div>

</div>
          </Segment>
        </Container>
</div>

<div className='nodisplay-tab'>

        <Container text className='marginBottom-25'>

          {/**/}
          <div className='cashbook-H'>

          <div
            style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
            onClick={this.handleRefresh}>
                  <span className='icon-button px-05 ml-05 border1' > <Icon name='refresh' /></span >
                </div>

        <div  style={{display: 'flex',flex: '80%',justifyContent: 'center',}} >
        <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
        Sale Book # {this.props.accountMonth}
       </div>
       </div>

       <div
       style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
       onClick={this.handleSearchSaleBook}>
              <span className='icon-button px-05 border1' > <Icon name='search' /></span >
            </div>


       </div>
       {/**/}
          <Segment  attached>

          

           

  </Segment>
      {this.renderLoading()}
      {this.props.saleBooks.map(this.renderListMobile)}
      {this.renderNoEntry()}

          <Segment >
          <div className='display-flex-fin font-16B'>
          <div className='sideF'>
          <Popup trigger={<span className="border-circle mr-05">?</span>
          } wide='very'>
          <b>Total of the all entries on the current page</b>
          </Popup>
          <b><span>Total : <Icon className="rupee" ></Icon>{sumT}{' '}</span>
          <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(sumT), {
          lang: "enIndian"
          }))} )</span>
          </b></div>
          <div className='sideF' >
          <Divider/>
          <Popup trigger={<span className="border-circle mr-05">?</span>
          } wide='very'>
          <b>Total of the all entries For current Search Criteria</b>
          </Popup>
          <b><span style={{color:'blue'}}>Grand Total </span> : <span> <Icon className="rupee" ></Icon>{this.props.sumGTotalSale}{' '}</span>
          <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(this.props.sumGTotalSale !==undefined && this.props.sumGTotalSale), {
          lang: "enIndian"
          }))} )</span>
          </b></div>

          </div>

        


          </Segment>
{/*  */}
      
<div className='disflex-paginator card-paginator media-600'>
      {this.renderPaginator()}
      <div className="disflex-paginator" >
        <div className= 'font-paginator'>Go To Page</div>
        <form>
              <input
              className='inputPage'
                placeholder="number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChange}
              />
              <span  className="btnNumber"  onClick={this.handleGoToPage} >
                Submit
              </span>
            </form>
      
              
      </div>
      <br/>
      </div>
      
      {/*  */}
      
      {/* <AdFindprop
      className1={"font-16B color-yellow pxy-10 font-family-bellMT"}
      className2={"font-16B color-white pxy-10 font-family-Calibri"}
      /> */}
      {/*  */}
      
        </Container>
        </div>
        </div>
      );
    }

    
}


const mapStateToProps = ({ finances, auth, form, }) => {
  const {accountMonth,noEntry,  fetching, offsetSale,limit, countSale } = finances;

  return {
    fetching,
    noEntry,
    limit,
    offset:offsetSale,
    count:countSale,
    accountMonth, 
    usersG: auth.usersG,
    form:form.SaleBookSearch,
    userA: auth.userA,
    saleBooks: finances.saleBooks,
    sumGTotalSale: finances.sumGTotalSale,
  };
};

export default connect(
  mapStateToProps,
{  ...actions,openModal,resetForm, }
)(SaleBook);
